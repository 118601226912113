<template>
	<div class="page">
		<div>
			<el-button type="primary" size="small" icon="el-icon-plus" @click="onCreate">新增
			</el-button>
		</div>
		<el-table :data="tableData" border :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
			style="width: 100%;overflow-y: auto;" size="small" height="70vh" class="margin-t-20" row-key="id"
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column prop="id" label="ID" width="120px"></el-table-column>
			<el-table-column prop="icon" label="图标" width="60px">
				<template slot-scope="scope">
					<i :class="scope.row.icon"></i>
				</template>
			</el-table-column>
			<el-table-column prop="title" label="菜单名称" width="200px"></el-table-column>
			<el-table-column prop="name" label="菜单标识" width="200px"></el-table-column>
			<el-table-column prop="url" label="访问路径">
				<template slot-scope="scope">
					<el-popover placement="top" width="200" trigger="manual" v-model="scope.row.urlShow">
						<el-link type="primary" slot="reference" @click="scope.row.urlShow = !scope.row.urlShow">
							{{scope.row.url}} <i class="el-icon-edit"></i>
						</el-link>
						<div class="row-edit-input">
							<el-input v-model="scope.row.url" placeholder="请输入访问路径"></el-input>
						</div>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text" @click="scope.row.urlShow = false">取消</el-button>
							<el-button type="primary" size="mini" @click="onQuick(scope.row.id, 'url', scope.row.url)">
								确定</el-button>
						</div>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" width="100px">
				<template slot-scope="scope">
					<el-popover placement="top" width="200" trigger="manual" v-model="scope.row.sortShow">
						<el-link type="primary" slot="reference" @click="scope.row.sortShow = !scope.row.sortShow">
							{{scope.row.sort}} <i class="el-icon-edit"></i>
						</el-link>
						<div class="row-edit-input">
							<el-input v-model="scope.row.sort" placeholder="请输入排序"></el-input>
						</div>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text" @click="scope.row.sortShow = false">取消</el-button>
							<el-button type="primary" size="mini"
								@click="onQuick(scope.row.id, 'sort', scope.row.sort)">确定</el-button>
						</div>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column prop="permission_name" label="权限标识" width="200px"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-link @click="addChild(scope.row)" type="primary" class="margin-r-10">添加子菜单</el-link>
					<el-link @click="onEdit(scope.row)" type="primary">编辑</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="编辑信息" :visible.sync="infoShow" width="40%" @close="resetInfo" :close-on-click-modal="false">
			<el-form :model="info" ref="info" :rules="rules" label-width="80px" :inline="false" size="small">
				<el-form-item label="上级菜单">
					<el-cascader v-model="info.group_name_all" :options="menuCascade" :props="{ checkStrictly: true }">
					</el-cascader>
				</el-form-item>
				<el-form-item label="菜单名称" prop="title">
					<el-input v-model="info.title" placeholder="例如:用户管理"></el-input>
				</el-form-item>
				<el-form-item label="标识" prop="name">
					<el-input v-model="info.name" placeholder="user"></el-input>
				</el-form-item>
				<el-form-item label="访问路径" prop="url">
					<el-input v-model="info.url"></el-input>
				</el-form-item>
				<el-form-item label="图标">
					<div class="icon-box" v-if="info.icon"><i :class="info.icon"></i></div>
					<el-button type="primary" size="mini" @click="iconsShow = true">选择图标</el-button>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input v-model="info.sort"></el-input>
				</el-form-item>
				<el-form-item label="权限标识" prop="permission_name">
					<el-select v-model="info.permission_name" placeholder="选择权限归属" clearable filterable>
						<el-option v-for="permission in permissions" :key="permission.name"
							:label="permission.title+'['+permission.name+']'" :value="permission.name">
						</el-option>
					</el-select>

				</el-form-item>
			</el-form>

			<span slot="footer">
				<el-button @click="infoShow = false">取消</el-button>
				<el-button :loading="submitting" type="primary" @click="subInfo">提交</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择图标" :visible.sync="iconsShow" width="60%">
			<ul class="icon-list">
				<li @click="chooseIcon(idx, icon)" v-for="(icon,idx) in icons" :key="idx" :class="{on:idx==iconIdx}">
					<span><i :class="icon"></i><span class="icon-name">{{icon}}</span></span>
				</li>
			</ul>
			<span slot="footer">
				<el-button @click="iconsShow = false">取消</el-button>
				<el-button type="primary" @click="setIcon">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "menus",
		data() {
			return {
				tableData: [],
				infoShow: false,
				saveType: 1, // 1新增 2编辑
				submitting: false,
				info: {
					pid: 0,
					title: '',
					name: '',
					group_name_all: [],
					url: '',
					icon: '',
					sort: 99,
					permission_name: '',
				},
				rules: {
					title: [{
						required: true,
						message: '请填写菜单名称',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请填写菜单标识',
						trigger: 'blur'
					}],
					url: [{
						required: true,
						message: '请填写访问地址',
						trigger: 'blur'
					}],
				},
				menuCascade: [],
				icons: [
					'el-icon-platform-eleme',
					'el-icon-eleme',
					'el-icon-delete-solid',
					'el-icon-delete',
					'el-icon-s-tools',
					'el-icon-setting',
					'el-icon-user-solid',
					'el-icon-user',
					'el-icon-phone',
					'el-icon-phone-outline',
					'el-icon-more',
					'el-icon-more-outline',
					'el-icon-star-on',
					'el-icon-star-off',
					'el-icon-s-goods',
					'el-icon-goods',
					'el-icon-warning',
					'el-icon-warning-outline',
					'el-icon-question',
					'el-icon-info',
					'el-icon-remove',
					'el-icon-circle-plus',
					'el-icon-success',
					'el-icon-error',
					'el-icon-zoom-in',
					'el-icon-zoom-out',
					'el-icon-remove-outline',
					'el-icon-circle-plus-outline',
					'el-icon-circle-check',
					'el-icon-circle-close',
					'el-icon-s-help',
					'el-icon-help',
					'el-icon-minus',
					'el-icon-plus',
					'el-icon-check',
					'el-icon-close',
					'el-icon-picture',
					'el-icon-picture-outline',
					'el-icon-picture-outline-round',
					'el-icon-upload',
					'el-icon-upload2',
					'el-icon-download',
					'el-icon-camera-solid',
					'el-icon-camera',
					'el-icon-video-camera-solid',
					'el-icon-video-camera',
					'el-icon-message-solid',
					'el-icon-bell',
					'el-icon-s-cooperation',
					'el-icon-s-order',
					'el-icon-s-platform',
					'el-icon-s-fold',
					'el-icon-s-unfold',
					'el-icon-s-operation',
					'el-icon-s-promotion',
					'el-icon-s-home',
					'el-icon-s-release',
					'el-icon-s-ticket',
					'el-icon-s-management',
					'el-icon-s-open',
					'el-icon-s-shop',
					'el-icon-s-marketing',
					'el-icon-s-flag',
					'el-icon-s-comment',
					'el-icon-s-finance',
					'el-icon-s-claim',
					'el-icon-s-custom',
					'el-icon-s-opportunity',
					'el-icon-s-data',
					'el-icon-s-check',
					'el-icon-s-grid',
					'el-icon-menu',
					'el-icon-share',
					'el-icon-d-caret',
					'el-icon-caret-left',
					'el-icon-caret-right',
					'el-icon-caret-bottom',
					'el-icon-caret-top',
					'el-icon-bottom-left',
					'el-icon-bottom-right',
					'el-icon-back',
					'el-icon-right',
					'el-icon-bottom',
					'el-icon-top',
					'el-icon-top-left',
					'el-icon-top-right',
					'el-icon-arrow-left',
					'el-icon-arrow-right',
					'el-icon-arrow-down',
					'el-icon-arrow-up',
					'el-icon-d-arrow-left',
					'el-icon-d-arrow-right',
					'el-icon-video-pause',
					'el-icon-video-play',
					'el-icon-refresh',
					'el-icon-refresh-right',
					'el-icon-refresh-left',
					'el-icon-finished',
					'el-icon-sort',
					'el-icon-sort-up',
					'el-icon-sort-down',
					'el-icon-rank',
					'el-icon-loading',
					'el-icon-view',
					'el-icon-c-scale-to-original',
					'el-icon-date',
					'el-icon-edit',
					'el-icon-edit-outline',
					'el-icon-folder',
					'el-icon-folder-opened',
					'el-icon-folder-add',
					'el-icon-folder-remove',
					'el-icon-folder-delete',
					'el-icon-folder-checked',
					'el-icon-tickets',
					'el-icon-document-remove',
					'el-icon-document-delete',
					'el-icon-document-copy',
					'el-icon-document-checked',
					'el-icon-document',
					'el-icon-document-add',
					'el-icon-printer',
					'el-icon-paperclip',
					'el-icon-takeaway-box',
					'el-icon-search',
					'el-icon-monitor',
					'el-icon-attract',
					'el-icon-mobile',
					'el-icon-scissors',
					'el-icon-umbrella',
					'el-icon-headset',
					'el-icon-brush',
					'el-icon-mouse',
					'el-icon-coordinate',
					'el-icon-magic-stick',
					'el-icon-reading',
					'el-icon-data-line',
					'el-icon-data-board',
					'el-icon-pie-chart',
					'el-icon-data-analysis',
					'el-icon-collection-tag',
					'el-icon-film',
					'el-icon-suitcase',
					'el-icon-suitcase-1',
					'el-icon-receiving',
					'el-icon-collection',
					'el-icon-files',
					'el-icon-notebook-1',
					'el-icon-notebook-2',
					'el-icon-toilet-paper',
					'el-icon-office-building',
					'el-icon-school',
					'el-icon-table-lamp',
					'el-icon-house',
					'el-icon-no-smoking',
					'el-icon-smoking',
					'el-icon-shopping-cart-full',
					'el-icon-shopping-cart-1',
					'el-icon-shopping-cart-2',
					'el-icon-shopping-bag-1',
					'el-icon-shopping-bag-2',
					'el-icon-sold-out',
					'el-icon-sell',
					'el-icon-present',
					'el-icon-box',
					'el-icon-bank-card',
					'el-icon-money',
					'el-icon-coin',
					'el-icon-wallet',
					'el-icon-discount',
					'el-icon-price-tag',
					'el-icon-news',
					'el-icon-guide',
					'el-icon-male',
					'el-icon-female',
					'el-icon-thumb',
					'el-icon-cpu',
					'el-icon-link',
					'el-icon-connection',
					'el-icon-open',
					'el-icon-turn-off',
					'el-icon-set-up',
					'el-icon-chat-round',
					'el-icon-chat-line-round',
					'el-icon-chat-square',
					'el-icon-chat-dot-round',
					'el-icon-chat-dot-square',
					'el-icon-chat-line-square',
					'el-icon-message',
					'el-icon-postcard',
					'el-icon-position',
					'el-icon-turn-off-microphone',
					'el-icon-microphone',
					'el-icon-close-notification',
					'el-icon-bangzhu',
					'el-icon-time',
					'el-icon-odometer',
					'el-icon-crop',
					'el-icon-aim',
					'el-icon-switch-button',
					'el-icon-full-screen',
					'el-icon-copy-document',
					'el-icon-mic',
					'el-icon-stopwatch',
					'el-icon-medal-1',
					'el-icon-medal',
					'el-icon-trophy',
					'el-icon-trophy-1',
					'el-icon-first-aid-kit',
					'el-icon-discover',
					'el-icon-place',
					'el-icon-location',
					'el-icon-location-outline',
					'el-icon-location-information',
					'el-icon-add-location',
					'el-icon-delete-location',
					'el-icon-map-location',
					'el-icon-alarm-clock',
					'el-icon-timer',
					'el-icon-watch-1',
					'el-icon-watch',
					'el-icon-lock',
					'el-icon-unlock',
					'el-icon-key',
					'el-icon-service',
					'el-icon-mobile-phone',
					'el-icon-bicycle',
					'el-icon-truck',
					'el-icon-ship',
					'el-icon-basketball',
					'el-icon-football',
					'el-icon-soccer',
					'el-icon-baseball',
					'el-icon-wind-power',
					'el-icon-light-rain',
					'el-icon-lightning',
					'el-icon-heavy-rain',
					'el-icon-sunrise',
					'el-icon-sunrise-1',
					'el-icon-sunset',
					'el-icon-sunny',
					'el-icon-cloudy',
					'el-icon-partly-cloudy',
					'el-icon-cloudy-and-sunny',
					'el-icon-moon',
					'el-icon-moon-night',
					'el-icon-dish',
					'el-icon-dish-1',
					'el-icon-food',
					'el-icon-chicken',
					'el-icon-fork-spoon',
					'el-icon-knife-fork',
					'el-icon-burger',
					'el-icon-tableware',
					'el-icon-sugar',
					'el-icon-dessert',
					'el-icon-ice-cream',
					'el-icon-hot-water',
					'el-icon-water-cup',
					'el-icon-coffee-cup',
					'el-icon-cold-drink',
					'el-icon-goblet',
					'el-icon-goblet-full',
					'el-icon-goblet-square',
					'el-icon-goblet-square-full',
					'el-icon-refrigerator',
					'el-icon-grape',
					'el-icon-watermelon',
					'el-icon-cherry',
					'el-icon-apple',
					'el-icon-pear',
					'el-icon-orange',
					'el-icon-coffee',
					'el-icon-ice-tea',
					'el-icon-ice-drink',
					'el-icon-milk-tea',
					'el-icon-potato-strips',
					'el-icon-lollipop',
					'el-icon-ice-cream-square',
					'el-icon-ice-cream-round',
				],
				iconsShow: false,
				iconIdx: '',
				permissions: []
			}
		},
		mounted() {
			this.getTabeData()
			this.getMenuCascade()
			this.getPermissions()
		},
		methods: {
			getTabeData() {
				let data = this.filter
				this.$util.get('menus', data).then(res => {
					this.tableData = res
				})
			},
			getMenuCascade() {
				this.$util.get('cascade', {
					model: 'Menu'
				}).then(res => {
					this.menuCascade = res
				})
			},
			getPermissions() {
				this.$util.get('slc', {
					model: 'Permission'
				}).then(res => {
					this.permissions = res
				})
			},
			onSearch() {
				this.page = 1
				this.getTabeData()
			},
			pageChange(page) {
				this.page = page
				this.getTableData()
			},
			onCreate() {
				this.saveType = 1
				this.infoShow = true
			},
			onEdit(row) {
				this.saveType = 2
				this.info = {
					id: row.id,
					pid: row.pid,
					title: row.title,
					name: row.name,
					group_name_all: row.group_name_all,
					url: row.url,
					icon: row.icon,
					sort: row.sort,
					permission_name: row.permission_name
				}
				this.infoShow = true
			},
			onQuick(id, field, val) {
				this.$util.put('menus/quick/' + id, {
					field: field,
					value: val
				}).then(() => {
					this.menuEach(this.tableData, id, field)
					this.getTabeData()
					this.getMenuCascade()
				})
			},
			menuEach(list, id, field) {
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					if (Object.prototype.hasOwnProperty.call(item, 'children')) {
						this.menuEach(item.children, id, field)
					}
					if (item.id == id) {
						item[field + 'Show'] = false
					}
				}
			},
			addChild(row) {
				this.saveType = 1
				this.infoShow = true
				let groups = row.group_name_all
				groups.push(row.name)
				this.info.group_name_all = groups
			},
			subInfo() {
				this.$refs['info'].validate(valid => {
					if (!valid) return
					
					this.submitting = true

					if (this.saveType == 1) {
						this.$util.post('menus', this.info).then(() => {
							this.getTabeData()
							this.getMenuCascade()
							this.infoShow = false
						}).finally(() => this.submitting = false)
					} else {
						this.$util.put('menus/' + this.info.id, this.info).then(() => {
							this.getTabeData()
							this.getMenuCascade()
							this.infoShow = false
						}).finally(() => this.submitting = false)
					}
				})
			},
			resetInfo() {
				this.info = {
					pid: 0,
					title: '',
					name: '',
					group_name_all: [],
					url: '',
					icon: '',
					sort: 99,
					permission_name: '',
				}
			},
			removeIcon() {
				this.info.icon = ''
			},
			beforeUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 / 2 < 500;

				if (!(isJPG || isPNG)) {
					this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 500kb!');
				}
				return (isJPG || isPNG) && isLt2M;
			},
			successIcon(res) {
				this.info.icon = res.data;
			},
			upIcon() {
				this.$refs.icon.submit();
			},
			chooseIcon(idx, icon) {
				this.icon = icon
				this.iconIdx = idx
			},
			setIcon() {
				this.info.icon = this.icon
				this.iconsShow = false
			}
		}
	}
</script>

<style scoped>
	.icon-box {
		display: inline-block;
		padding: 0 15px;
	}

	.icon-list {
		border: #eee 1px solid;
		padding: 0;
		overflow: hidden;
		border-right: none;
		border-bottom: none;
		height: 400px;
		overflow-y: scroll;
	}

	.icon-list li {
		float: left;
		width: 10%;
		list-style: none;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
		border-right: #eee 1px solid;
		border-bottom: #eee 1px solid;
	}

	.icon-list li.on {
		background-color: #eee;
	}

	.icon-list li i {
		display: block;
		font-size: 24px;
	}

	.icon-list li span span {
		display: none;
	}

	.row-edit-input {
		margin-bottom: 10px;
	}

	.el-button+.btn-pop {
		margin-left: 10px;
	}
</style>
